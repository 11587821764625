import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

const ArticlesPageStyle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px 20px;
    position: relative;
    .articles-wrap {
        width: 780px;
        h1 {
            text-align: center;
            font-weight: bold;
            line-height: 50px;
            margin-bottom: 20px;
        }
        .articles-grid {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;
        }
        .current-filter-row {
            width: 100%;
            display: none;
            align-items: center;
            flex-wrap: wrap;
            padding: 0 20px;
            h3 {
                color: var(--dark);
            }
            a {
                font-size: 14px;
                display: block;
                padding: 4px 24px 4px 10px;
                border-radius: 6px;
                background-color: var(--lightGray);
                margin-right: 10px;
            }
            a.current-tag {
                background: var(--lightGray) url("https://mtimg.onemiao.cn/web-images/remove.svg") right 5px center no-repeat;
                background-size: 16px;
            }
            a.reset-filter {
                text-align: center;
                padding: 4px 10px;
                background-color: var(--primary-color);
                color: white;
            }
        }
        .current-filter-row.show {
            display: flex;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        padding: 20px 10px;
        .articles-wrap {
            width: 100%;
            .articles-head {
                padding: 0;
                padding-left: 10px;
            }
            .current-filter-row {
                padding: 0px 0px 10px;
                h3 {
                    width: 100%;
                    text-indent: 10px;
                }
                a {
                    margin: 5px;
                }
            }
        }
    }
`

const ArticleCardStyle = styled.div`
    width: 50%;
    padding: 20px;
    display: flex;
    align-items: stretch;
    a {
        width: 100%;
        background-color: white;
        overflow: hidden;
        border-radius: 6px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        .cover {
            width: 100%;
            height: 148px;
            img {
                width: 100%;
                height: 148px;
                object-fit: cover;
            }
        }
        .text-wrap {
            width: 100%;
            padding: 10px;
            h4 {
                font-size: 18px;
                margin-bottom: 8px;
            }
            .tags-row {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                span {
                    display: block;
                    background-color: var(--lightGray);
                    border-radius: 2px;
                    font-size: 12px;
                    color: var(--dark);
                    line-height: 20px;
                    padding: 0 10px;
                    margin-right: 8px;
                    margin-bottom: 8px;
                }
                span.default {
                    background-color: var(--primary-color);
                    color: white;
                }
                span.uts {
                    background-color: var(--secondary-color);
                    color: white;
                }
                span.gray {
                    background-color: var(--blue);
                    color: white;
                }
                span.brown {
                    background-color: var(--deepBlue);
                    color: white;
                }
            }
            p {
                color: var(--dark);
                font-size: 12px;
            }
        }
    }
    @media (max-width: 900px) {
        width: 100%;
        padding: 10px 0;
        a {
            .cover {
                height: unset;
                img {
                    height: unset;
                }
            }
            .text-wrap {
                padding: 10px 20px 20px;
            }
        }
    }
`

const TagFiltersListStyle = styled.div`
    width: 150px;
    padding: 90px 0 0 30px;
    h3 {
        color: var(--dark);
        margin-bottom: 10px;
    }
    .tags-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        h3 {
            font-weight: bold;
            color: black;
            margin-bottom: 5px;
        }
        a {
            width: 100%;
            background-color: white;
            border-radius: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 10px;
            margin-top: 5px;
            span {
                font-size: 14px;
            }
            span.name {
                color: var(--dark);
            }
            span.count {
                font-weight: bold;
            }
            :hover {
                background-color: var(--lightGray);
            }
        }
        a.hide {
            display: none;
        }
    }
    @media (max-width: 768px) {
        padding: 0 0 20px;
        width: 100%;
        h3.filter-title {
            line-height: 32px;
            text-indent: 10px;
            background: var(--primary-color) url("https://mtimg.onemiao.cn/web-images/search.svg") right 14px center no-repeat;
            background-size: 16px;
            border-radius: 6px;
            color: white;
        }
        .filter-title.close {
            background: var(--primary-color) url("https://mtimg.onemiao.cn/web-images/caret-sm-up.svg") right 14px center no-repeat;
            background-size: 16px;
        }
        .tags-filter {
            width: 100%;
            display: flex;
            .tags-group {
                h3 {
                    width: 100%;
                    text-align: left;
                    text-indent: 10px;
                }
            }
        }
        .tags-filter.hide {
            display: none;
        }
    }
`

function parseArticles(originArticles) {
    const newArticles = originArticles.map(article => {
        const typeTags = article.node.properties.TypeTags.value;
        const AreaTags = article.node.properties.AreaTags.value;
        const ProductTags = article.node.properties.ProductTags.value;

        const updateTime = {
            name: article.node.properties.publicTime.value.start,
        }
        const tags = [updateTime, ...ProductTags, ...typeTags, ...AreaTags];
        
        const articleCover = article.node.properties.cover;

        return {
            title: article.node.title,
            id: article.node.id,
            description: article.node.properties.Description.value,
            tags: tags,
            cover: articleCover ? articleCover.value : "",
        }
    });

    return newArticles;
}

function ArticleCard({ article }) {
    return (
        <ArticleCardStyle id={article.id}>
            <Link to={`/post/${article.id}`} state={{ fromHome: false }}>
                <div className="cover">
                    <img src={article.cover} alt="" />
                </div>
                <div className="text-wrap">
                    <h4>{article.title}</h4>
                    <div className="tags-row">
                        {article.tags.map((tag, index) => {
                            const tagClassName = index === 1 ? tag.color : "";
                            return <span key={article.id+`tag`+index} className={tagClassName}>{tag.name}</span>
                        })}
                    </div>
                    <p>{article.description}</p>
                </div>
            </Link>
        </ArticleCardStyle>
    )
}

function countArticlesInTags(articles) {
    const articlesForCount = JSON.parse(JSON.stringify(articles));
    const counts = articlesForCount
        .map(article => {
            article.tags.shift();
            return article.tags;
        })
        .flat()
        .reduce((acc, tag) => {
            const existingTag = acc[tag.id];
            if (existingTag) {
                existingTag.count += 1;
            } else {
                acc[tag.id] = {
                    id: tag.id,
                    name: tag.name,
                    count: 1,
                };
            }
            return acc;
        }, {});

    return counts;
}

function updateCurrentTags(currentTags, newTag) {
    if (currentTags) {
        if (currentTags.includes(newTag)) {
            return currentTags;
        } else {
            return [...currentTags, newTag];
        }
    } else {
        return [newTag];
    }
}

function removeFilterTag(currentTags, tagToRemove) {
    if (currentTags) {
        const newTags = currentTags.filter(tag => tag !== tagToRemove);
        return newTags;
    } else {
        return [];
    }
}

export default function ResourceCenter({ data, location }) {
    const resourcePosts = parseArticles(data.articles.edges);
    //console.log(resourcePosts);

    const countTags = countArticlesInTags(resourcePosts);
    
    const allTags = data.allTags.edges[0].node.properties;
    //console.log(allTags);

    //console.log(location.state.filterTags);
    const currentFilterTags = (location.state && location.state.filterTags) ? location.state.filterTags : [];

    const postsForShow = (currentFilterTags.length > 0) ? resourcePosts.filter(article => {
        const tags = article.tags;
        const tagsInCurrentFilter = tags.filter(tag => currentFilterTags.includes(tag.name));
        return tagsInCurrentFilter.length > 0;
    }) : resourcePosts;

    const [showFilters, setFilterTags] = useState(false);

    return (
        <ArticlesPageStyle>
            <div className="articles-wrap">
                <h1>苗通资源中心</h1>
                <div className={(currentFilterTags.length > 0) ? "current-filter-row show" : "current-filter-row"}>
                    <h3>已选文章类型：</h3>
                    {
                        currentFilterTags.map((tag, index) => {
                            return <Link to="/resource" key={`filter-${index}`} className="current-tag" state={{ filterTags: removeFilterTag(currentFilterTags, tag) }}>{tag}</Link>
                        })
                    }
                    <Link to="/resource" className="reset-filter">显示全部</Link>
                </div>
                <div className="articles-grid">
                    {postsForShow.map(article => (
                        <ArticleCard key={article.id} article={article} />
                    ))}
                </div>
            </div>
            <TagFiltersListStyle>
                <h3 className={showFilters ? "filter-title close" : "filter-title"} onClick={() => setFilterTags(!showFilters)}>按类型查找:</h3>
                <div className={showFilters ? "tags-filter" : "tags-filter hide"}>
                    <div className="tags-group">
                        <h3>苗通产品</h3>
                        {allTags.ProductTags.value.map(tag => (
                            <Link 
                                key={tag.id} 
                                to={`/resource`} 
                                state={{ filterTags: updateCurrentTags(currentFilterTags, tag.name) }} 
                                className={countTags[tag.id] ? "" : "hide"}
                            >
                                <span className="name">{tag.name}</span>
                                <span className="count">{countTags[tag.id] ? countTags[tag.id].count : 0}</span>
                            </Link>
                        ))}
                    </div>
                    <div className="tags-group">
                        <h3>文章主题</h3>
                        {allTags.TypeTags.value.map(tag => (
                            <Link 
                                key={tag.id} 
                                to={`/resource`} 
                                state={{ filterTags: updateCurrentTags(currentFilterTags, tag.name) }}
                                className={countTags[tag.id] ? "" : "hide"}
                            >
                                <span className="name">{tag.name}</span>
                                <span className="count">{countTags[tag.id] ? countTags[tag.id].count : 0}</span>
                            </Link>
                        ))}
                    </div>
                    <div className="tags-group">
                        <h3>文章领域</h3>
                        {allTags.AreaTags.value.map(tag => (
                            <Link 
                                key={tag.id} 
                                to={`/resource`} 
                                state={{ filterTags: updateCurrentTags(currentFilterTags, tag.name) }}
                                className={countTags[tag.id] ? "" : "hide"}
                            >
                                <span className="name">{tag.name}</span>
                                <span className="count">{countTags[tag.id] ? countTags[tag.id].count : 0}</span>
                            </Link>
                        ))}
                    </div>
                </div>
            </TagFiltersListStyle>
        </ArticlesPageStyle>
    );
}


export const query = graphql`
    query {
        articles: allNotion(
            sort: {fields: properties___publicTime___value___start, order: DESC}
            filter: {
                properties: {
                    ContentType: {value: {elemMatch: { name: { eq: "Article" } } } },
                    PageType: {value: {elemMatch: { name: { in: ["Resource", "Solution"] } } } },
                }
            }
        ) {
            edges {
                node {
                    id
                    title
                    properties {
                        cover {
                            value
                        }
                        Description {
                            value
                        }
                        publicTime {
                            value {
                                start(formatString: "YYYY-MM-DD")
                            }
                        }
                        ProductTags {
                            value {
                                name
                                color
                                id
                            }
                        }
                        AreaTags {
                            value {
                                name
                                id
                            }
                        }
                        TypeTags {
                            value {
                                name
                                id
                            }
                        }
                    }
                }
            }
        }
        allTags: allNotion(filter: {
            id: {eq: "be23a414-4124-5d55-8f48-92b53bee0a1f"}
        }) {
            edges {
                node {
                    properties {
                        AreaTags {
                            value {
                                id
                                name
                            }
                        }
                        ProductTags {
                            value {
                                name
                                id
                                color
                            }
                        }
                        TypeTags {
                            value {
                                name
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`